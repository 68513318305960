.Editor {
    margin: 0.5em;
    padding-top: 3em;
    font-weight: 300;
    text-align: start;
}

.Editor p,
.Editor ul,
.Editor ol,
.Editor strong {
    margin-bottom: 1em;
    font-family: 'Raleway', sans-serif;
}

.Editor h2 {
    font-weight: 700;
}

.Editor strong {
    font-weight: 600;
}