.blogImage{
    height: 20em;
    width: 20em;
    object-fit: cover;
    border-radius: 1em;
}
.icons{
    position: relative;
    bottom: 3em;
    display: flex;
    justify-content: space-evenly;
    width: 20em;
}
.editor div{
    font-size: 1.05em;
    padding: 1em;
}
#ButtonContainer > *{
   width: 9em;
}