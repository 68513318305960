.PFSBlog{
    min-height: 24em;
    max-height: 24em;
}
.iconContainer i{
    cursor: pointer;
}
.blogImage{
    height: 12em;
    object-fit: cover;
}
.blogTitle{
    height: 4.25em;
    word-wrap:normal;
}